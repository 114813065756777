<script lang="ts">
  import { results } from "../store";
  import type { ResultName } from "../types";
  export let contrast: string;
  export let isDark: boolean;
  $: resultName = isDark
    ? (`${contrast}Dark` as ResultName)
    : (`${contrast}Light` as ResultName);

  $: type = $results[resultName].conformance.checks.small ? "pass" : "fail";
  $: ratio = parseFloat(
    (1 / $results[resultName].conformance.ratio).toFixed(1)
  );

  const passSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>`;
  const failSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
  ><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>`;
</script>

<div class={`badge ${type}`}>
  {@html $results[resultName].conformance.checks.small ? passSvg : failSvg}
  <span class="ratio">{ratio}:1</span>
</div>

<style>
  .badge {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.25rem 0.5rem;
    font-size: var(--font-size-label);
    border-radius: var(--border-radius-pill);
  }
  .pass {
    background-color: var(--color-success);
  }
  .fail {
    background-color: var(--color-danger);
  }
  @media screen and (max-width: 600px) {
    .badge {
      padding: 0.25rem 0.25rem;
      border-radius: 100%;
    }
    .ratio {
      display: none;
    }
  }
  @media (prefers-color-scheme: dark) {
    .badge {
      color: var(--color-text-reversed);
    }
  }
</style>
