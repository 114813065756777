/**
 * HSL to RGB
 * The range of the input parameters is H: [0, 360], S: [0, 100], L: [0, 100].
 * The range of all output values is [0, 255].
 */
export function hslToRgb(h, s, l) {
    s /= 100;
    l /= 100;
    const k = (n) => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = (n) => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
    const rgb = [255 * f(0), 255 * f(8), 255 * f(4)];
    return rgb.map((c) => Math.round(c)); // rgbs with decimals can be a problem
}
/**
 * RGB to HEX
 * The range of all input values is [0, 255].
 */
export function rgbToHex(rgb) {
    let r = rgb[0].toString(16);
    let g = rgb[1].toString(16);
    let b = rgb[2].toString(16);
    if (r.length === 1)
        r = "0" + r;
    if (g.length === 1)
        g = "0" + g;
    if (b.length === 1)
        b = "0" + b;
    return "#" + r + g + b;
}
/**
 * HEX to RGB
 * Takes a hex-like string (`#0000ff`, `0000ff`, `#00f` or `00f`)
 * and returns an `[r,g,b]` array of numbers.
 */
export function hexToRgb(hex) {
    // convert 3-char hex to 6-char hex
    hex = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (_, r, g, b) => (r + r + g + g + b + b));
    return /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
        .exec(hex)
        .slice(1)
        .map((a) => parseInt(a, 16));
}
/**
 * RGB to HSL
 */
export function rgbToHsl(rgb) {
    let r = rgb[0], g = rgb[1], b = rgb[2];
    // Make r, g, and b fractions of 1
    r /= 255;
    g /= 255;
    b /= 255;
    // Find greatest and smallest channel values
    const cmin = Math.min(r, g, b), cmax = Math.max(r, g, b), delta = cmax - cmin;
    let h = 0, s = 0, l = 0;
    // Calculate hue
    // No difference
    if (delta == 0)
        h = 0;
    // Red is max
    else if (cmax == r)
        h = ((g - b) / delta) % 6;
    // Green is max
    else if (cmax == g)
        h = (b - r) / delta + 2;
    // Blue is max
    else
        h = (r - g) / delta + 4;
    h = Math.round(h * 60);
    // Make negative hues positive behind 360°
    if (h < 0)
        h += 360;
    // Calculate lightness
    l = (cmax + cmin) / 2;
    // Calculate saturation
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    // Multiply l and s by 100
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);
    return [h, s, l];
}
/**
 * Takes an rgb-like string (`12,12,12` or `rgb(12,12,12)`)
 * and returns an `[r,g,b]` array of numbers.
 */
function normalizeRgb(rgb) {
    return /^(rgb\()?(\d{1,3}),(\d{1,3}),(\d{1,3})\)?$/i
        .exec(rgb)
        .slice(2)
        .map((a) => Number(a));
}
/**
 * Detects if a color string is hex-like.
 */
function isHex(color) {
    return /^#?([a-f\d]{6}|[a-f\d]{3})$/i.test(color);
}
/**
 * Detects if a color string is rgb-like.
 */
function isRgb(color) {
    return /^(rgb\()?(\d{1,3}),(\d{1,3}),(\d{1,3})\)?$/i.test(color);
}
/**
 * Attempts to return an `[r,g,b]` array of numbers from a string.
 * Falls back to `[255,255,255]`
 * (Note: unused right now, keep around for the input modal)
 */
export function getRgb(color) {
    if (isHex(color)) {
        return hexToRgb(color);
    }
    else if (isRgb(color)) {
        return normalizeRgb(color);
    }
    else
        return [255, 255, 255];
}
/**
 * Returns the luminance from an `[r,g,b]` array of numbers.
 * https://en.wikipedia.org/wiki/Luminance
 */
export function getLuminance(color) {
    const a = color.map((v) => {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}
/**
 * Returns contrast ratio for two given color luminances.
 */
export function getRatio(a, b) {
    return a > b ? (b + 0.05) / (a + 0.05) : (a + 0.05) / (b + 0.05);
}
/**
 * Checks the contrast ratio between two colors against WCAG 2.1 requirements.
 */
export function getConformance(colors, conformanceStandard) {
    const ratio = getRatio(colors[0].luminance, colors[1].luminance);
    return {
        ratio,
        checks: {
            large: conformanceStandard === "AA" ? ratio < 1 / 3 : ratio < 1 / 4.5,
            small: conformanceStandard === "AA" ? ratio < 1 / 4.5 : ratio < 1 / 7,
        },
    };
}
