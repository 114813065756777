<script lang="ts">
  import { conformanceStandard } from "../store";

  function handleStandardChange(e) {
    conformanceStandard.set(e.target.value);
  }
</script>

<fieldset>
  <legend class="sr-only">Select a conformance standard</legend>
  <label for="radio-aa">AA</label>
  <span class="toggle-wrapper">
    <input
      type="radio"
      id="radio-aa"
      name="conformanceStandard"
      value="AA"
      checked={$conformanceStandard === "AA"}
      on:input={handleStandardChange}
    />
    <input
      type="radio"
      id="radio-aaa"
      name="conformanceStandard"
      value="AAA"
      checked={$conformanceStandard === "AAA"}
      on:input={handleStandardChange}
    />
    <span aria-hidden="true" class="toggle-switch" />
    <span aria-hidden="true" class="toggle-bg" />
  </span>
  <label for="radio-aaa">AAA</label>
</fieldset>

<style>
  fieldset {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    margin-right: 1rem;
    /* Reset */
    border: none;
    padding: 0;
  }

  label {
    cursor: pointer;
    font-size: var(--font-size-label);
  }

  .toggle-wrapper > input[type="radio"] {
    z-index: 1;
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .toggle-wrapper > input[type="radio"]:focus ~ .toggle-switch {
    background-color: var(--color-text-muted);
  }

  .toggle-wrapper {
    position: relative;
    display: flex;
    width: 3em;
    height: 1.5em; /* = half of width */
    border-radius: 1.5em; /* = height */
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  .toggle-switch {
    z-index: 2;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 100%;
    width: 50%;
    height: 100%;
    border-radius: var(--border-radius-pill);
    background-color: white;
    box-shadow: 0 0.1em 0.25em rgba(0, 0, 0, 0.2);
    transition: all 0.1s ease-out;
  }

  .toggle-bg {
    z-index: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1.5em; /* = height */
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0.05em 0.25em rgba(0, 0, 0, 0.2);
    transition: all 0.1s ease-out;
  }

  #radio-aaa:checked ~ .toggle-switch {
    right: 0;
    left: 50%;
  }

  #radio-aaa:checked ~ .toggle-bg {
    background-color: var(--color-success);
  }
</style>
