<script lang="ts">
  import Preview from "./components/Preview.svelte";
  import ConformanceToggle from "./components/ConformanceToggle.svelte";
  import AboutModal from "./components/AboutModal.svelte";
  import ExportModal from "./components/ExportModal.svelte";
  import Form from "./components/Form.svelte";
</script>

<div class="wrapper">
  <header>
    <div>
      <img src="/logo.svg" alt="" />
      <h1>Color Contrast</h1>
    </div>
    <div>
      <ConformanceToggle />
      <AboutModal />
      <div class="hide-mobile"><ExportModal /></div>
    </div>
  </header>
  <main>
    <div id="col-left">
      <h2 class="sr-only">Select your colors</h2>
      <Form theme="Light" />
      <Form theme="Dark" />
    </div>
    <div id="col-right">
      <Preview />
    </div>
  </main>
</div>

<style>
  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  header {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    background-color: var(--color-bg-alt);
    padding: 1rem var(--margin-horizontal);
    box-shadow: var(--shadow-offset) 1rem var(--shadow-color);
  }
  header > div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  header img {
    height: 1em;
    width: 1em;
  }
  header h1 {
    text-transform: uppercase;
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-bold);
  }
  main {
    flex: 1;
    display: flex;
  }
  main > * {
    flex: 1;
  }
  #col-left {
    display: flex;
    gap: 1.5rem;
    padding: var(--margin-vertical) var(--margin-horizontal);
  }

  @media screen and (max-width: 600px) {
    .hide-mobile {
      display: none;
    }
    header > div {
      gap: 0.5rem;
    }
    #col-left {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 1024px) {
    main {
      flex-direction: column;
    }
  }
</style>
