<script lang="ts">
  import { formValues } from "../store";
  import type { Color } from "../types";
  import { getRatio, hexToRgb, rgbToHsl } from "../utils";

  export let color: [string, Color];
  $: [name, c] = color;

  $: wContrast = getRatio(c.luminance, 1); // fff luminance is 1
  $: bContrast = getRatio(c.luminance, 0); // 000 luminance is 0
  $: textColor = wContrast < bContrast ? "white" : "black";

  function setColor(e) {
    const value = $formValues[name];
    if (e.target.name === "hue") {
      value[0] = Number(e.target.value);
    } else if (e.target.name === "saturation") {
      value[1] = Number(e.target.value);
    } else if (e.target.name === "lightness") {
      value[2] = Number(e.target.value);
    }
    formValues.set({ ...$formValues, [name]: value });
  }

  function handleChange(e) {
    const hex = e.target.value;
    const hsl = rgbToHsl(hexToRgb(hex));
    formValues.set({ ...$formValues, [name]: hsl });
  }
</script>

<div class="wrapper" style={`background-color:${c.hex};color:${textColor};`}>
  <div class="input-header">
    <h3>{c.label}</h3>
    <label class="color-picker">
      <svg
        role="presentation"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        ><path
          d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
        /></svg
      >
      <span class="sr-only">Pick a color</span>
      <input
        class="sr-only"
        {name}
        type="color"
        value={c.hex}
        on:input={handleChange}
      />
    </label>
  </div>
  <span>
    <span class="slider">
      <label for={`${name}-h`}>Hue</label>
      <input
        type="range"
        id={`${name}-h`}
        value={c.value[0]}
        name="hue"
        min="0"
        max="360"
        on:input={setColor}
      /></span
    ><span class="slider">
      <label for={`${name}-s`}>Saturation</label>
      <input
        type="range"
        id={`${name}-s`}
        value={c.value[1]}
        name="saturation"
        min="0"
        max="100"
        on:input={setColor}
      /></span
    ><span class="slider">
      <label for={`${name}-l`}>Lightness</label>
      <input
        type="range"
        id={`${name}-l`}
        value={c.value[2]}
        name="lightness"
        min="0"
        max="100"
        on:input={setColor}
      /></span
    ></span
  >
</div>

<style>
  .wrapper {
    padding: 1.5rem;
  }
  .input-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
  h3 {
    font-weight: unset;
    font-size: var(--font-size-body);
  }
  .color-picker {
    padding: 0.5rem;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 100%;
  }
  .color-picker:focus-within {
    border-color: currentColor;
  }
  .color-picker svg {
    height: 1em;
    width: 1em;
  }
  .slider {
    display: grid;
    grid-template-columns: 5rem auto;
  }
  .slider label {
    font-size: var(--font-size-label);
  }
  input[type="range"] {
    /* reset */
    width: 100%;
  }
</style>
