import { writable, derived } from "svelte/store";
import { getConformance, getLuminance, hslToRgb, rgbToHex } from "./utils";
const qs = new URL(window.location.href).searchParams;
function parseQs(key) {
    const string = qs.get(key); // "1,2,3"
    if (string) {
        const array = string.split(","); // ["1","2","3"]
        return array.map((v) => Number(v)); // [1,2,3]
    }
    return null;
}
const initialFormValues = {
    backgroundLight: parseQs("backgroundLight") || [0, 0, 100],
    backgroundDark: parseQs("backgroundDark") || [240, 50, 13],
    backgroundSecondaryLight: parseQs("backgroundSecondaryLight") || [
        204, 100, 83,
    ],
    backgroundSecondaryDark: parseQs("backgroundSecondaryDark") || [233, 82, 37],
    textLight: parseQs("textLight") || [0, 0, 0],
    textDark: parseQs("textDark") || [0, 0, 100],
    primaryLight: parseQs("primaryLight") || [232, 100, 50],
    primaryDark: parseQs("primaryDark") || [194, 100, 57],
    accentLight: parseQs("accentLight") || [0, 100, 33],
    accentDark: parseQs("accentDark") || [343, 100, 77],
};
export const formValues = writable(initialFormValues);
export const conformanceStandard = writable(qs.get("conformanceStandard") === "AAA" ? "AAA" : "AA");
export const colors = derived(formValues, ($formValues) => {
    const colorList = {
        backgroundLight: {
            label: "Background",
            value: $formValues.backgroundLight,
            hex: rgbToHex(hslToRgb($formValues.backgroundLight[0], $formValues.backgroundLight[1], $formValues.backgroundLight[2])),
            rgb: hslToRgb($formValues.backgroundLight[0], $formValues.backgroundLight[1], $formValues.backgroundLight[2]),
            luminance: getLuminance(hslToRgb($formValues.backgroundLight[0], $formValues.backgroundLight[1], $formValues.backgroundLight[2])), // TODO: avoid calling hslToRgb twice
        },
        backgroundSecondaryLight: {
            label: "Background Secondary",
            value: $formValues.backgroundSecondaryLight,
            hex: rgbToHex(hslToRgb($formValues.backgroundSecondaryLight[0], $formValues.backgroundSecondaryLight[1], $formValues.backgroundSecondaryLight[2])),
            rgb: hslToRgb($formValues.backgroundSecondaryLight[0], $formValues.backgroundSecondaryLight[1], $formValues.backgroundSecondaryLight[2]),
            luminance: getLuminance(hslToRgb($formValues.backgroundSecondaryLight[0], $formValues.backgroundSecondaryLight[1], $formValues.backgroundSecondaryLight[2])),
        },
        textLight: {
            label: "Text",
            value: $formValues.textLight,
            hex: rgbToHex(hslToRgb($formValues.textLight[0], $formValues.textLight[1], $formValues.textLight[2])),
            rgb: hslToRgb($formValues.textLight[0], $formValues.textLight[1], $formValues.textLight[2]),
            luminance: getLuminance(hslToRgb($formValues.textLight[0], $formValues.textLight[1], $formValues.textLight[2])),
        },
        primaryLight: {
            label: "Primary",
            value: $formValues.primaryLight,
            hex: rgbToHex(hslToRgb($formValues.primaryLight[0], $formValues.primaryLight[1], $formValues.primaryLight[2])),
            rgb: hslToRgb($formValues.primaryLight[0], $formValues.primaryLight[1], $formValues.primaryLight[2]),
            luminance: getLuminance(hslToRgb($formValues.primaryLight[0], $formValues.primaryLight[1], $formValues.primaryLight[2])),
        },
        accentLight: {
            label: "Accent",
            value: $formValues.accentLight,
            hex: rgbToHex(hslToRgb($formValues.accentLight[0], $formValues.accentLight[1], $formValues.accentLight[2])),
            rgb: hslToRgb($formValues.accentLight[0], $formValues.accentLight[1], $formValues.accentLight[2]),
            luminance: getLuminance(hslToRgb($formValues.accentLight[0], $formValues.accentLight[1], $formValues.accentLight[2])),
        },
        backgroundDark: {
            label: "Background",
            value: $formValues.backgroundDark,
            hex: rgbToHex(hslToRgb($formValues.backgroundDark[0], $formValues.backgroundDark[1], $formValues.backgroundDark[2])),
            rgb: hslToRgb($formValues.backgroundDark[0], $formValues.backgroundDark[1], $formValues.backgroundDark[2]),
            luminance: getLuminance(hslToRgb($formValues.backgroundDark[0], $formValues.backgroundDark[1], $formValues.backgroundDark[2])),
        },
        backgroundSecondaryDark: {
            label: "Background Secondary",
            value: $formValues.backgroundSecondaryDark,
            hex: rgbToHex(hslToRgb($formValues.backgroundSecondaryDark[0], $formValues.backgroundSecondaryDark[1], $formValues.backgroundSecondaryDark[2])),
            rgb: hslToRgb($formValues.backgroundSecondaryDark[0], $formValues.backgroundSecondaryDark[1], $formValues.backgroundSecondaryDark[2]),
            luminance: getLuminance(hslToRgb($formValues.backgroundSecondaryDark[0], $formValues.backgroundSecondaryDark[1], $formValues.backgroundSecondaryDark[2])),
        },
        textDark: {
            label: "Text",
            value: $formValues.textDark,
            hex: rgbToHex(hslToRgb($formValues.textDark[0], $formValues.textDark[1], $formValues.textDark[2])),
            rgb: hslToRgb($formValues.textDark[0], $formValues.textDark[1], $formValues.textDark[2]),
            luminance: getLuminance(hslToRgb($formValues.textDark[0], $formValues.textDark[1], $formValues.textDark[2])),
        },
        primaryDark: {
            label: "Primary",
            value: $formValues.primaryDark,
            hex: rgbToHex(hslToRgb($formValues.primaryDark[0], $formValues.primaryDark[1], $formValues.primaryDark[2])),
            rgb: hslToRgb($formValues.primaryDark[0], $formValues.primaryDark[1], $formValues.primaryDark[2]),
            luminance: getLuminance(hslToRgb($formValues.primaryDark[0], $formValues.primaryDark[1], $formValues.primaryDark[2])),
        },
        accentDark: {
            label: "Accent",
            value: $formValues.accentDark,
            hex: rgbToHex(hslToRgb($formValues.accentDark[0], $formValues.accentDark[1], $formValues.accentDark[2])),
            rgb: hslToRgb($formValues.accentDark[0], $formValues.accentDark[1], $formValues.accentDark[2]),
            luminance: getLuminance(hslToRgb($formValues.accentDark[0], $formValues.accentDark[1], $formValues.accentDark[2])),
        },
    };
    return colorList;
});
export const results = derived([colors, conformanceStandard], ([$colors, $conformanceStandard]) => {
    const pairs = {
        textOnBackgroundLight: {
            colors: [$colors.textLight, $colors.backgroundLight],
        },
        textOnBackgroundDark: {
            colors: [$colors.textDark, $colors.backgroundDark],
        },
        textOnBackgroundSecondaryLight: {
            colors: [$colors.textLight, $colors.backgroundSecondaryLight],
        },
        textOnBackgroundSecondaryDark: {
            colors: [$colors.textDark, $colors.backgroundSecondaryDark],
        },
        primaryOnBackgroundLight: {
            colors: [$colors.primaryLight, $colors.backgroundLight],
        },
        primaryOnBackgroundDark: {
            colors: [$colors.primaryDark, $colors.backgroundDark],
        },
        primaryOnBackgroundSecondaryLight: {
            colors: [$colors.primaryLight, $colors.backgroundSecondaryLight],
        },
        primaryOnBackgroundSecondaryDark: {
            colors: [$colors.primaryDark, $colors.backgroundSecondaryDark],
        },
        accentOnBackgroundLight: {
            colors: [$colors.accentLight, $colors.backgroundLight],
        },
        accentOnBackgroundDark: {
            colors: [$colors.accentDark, $colors.backgroundDark],
        },
        accentOnBackgroundSecondaryLight: {
            colors: [$colors.accentLight, $colors.backgroundSecondaryLight],
        },
        accentOnBackgroundSecondaryDark: {
            colors: [$colors.accentDark, $colors.backgroundSecondaryDark],
        },
    };
    return Object.entries(pairs).reduce((acc, [key, value]) => {
        acc[key] = Object.assign(Object.assign({}, acc[key]), { conformance: getConformance(value.colors, $conformanceStandard) });
        return acc;
    }, {});
});
