<script lang="ts">
  import { Modal, showModal } from "svelte-micromodal";
</script>

<div>
  <!-- this wrapper avoids UI bugs with collapsing margins -->
  <Modal
    title="About"
    id="about-modal"
    closeLabel="Close modal"
    containerStyles="color:var(--color-text);background-color:var(--color-bg-alt);"
  >
    <p>
      This contrast checker is based on the <a
        href="https://www.w3.org/TR/WCAG20/"
        >Web Content Accessibility Guidelines</a
      > (WCAG) 2 standards.
    </p>
    <p>
      WCAG 2 measures the contrast ratio of two colors by calculating their
      perceived <em>luminance</em> or brightness difference. A contrast ratio of
      4.5:1 is the minimum WCAG 2 standard (AA). Large text (18pt or 14pt if bold)
      can pass the standard at a lower ratio of 3:1.
    </p>
    <p>
      AA is the minimum contrast ratio standard, while AAA is the enhanced
      standard. The stricter AAA-level requires a contrast ratio of 7:1 for
      normal text and 4.5:1 for large text.
    </p>
    <p class="italic">
      Brought to you by <a href="https://emdash.space/">emdash.space</a>
    </p>
  </Modal>
  <button class="menu-button" on:click={() => showModal("about-modal")}>
    About
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      ><circle cx="12" cy="12" r="10" /><line
        x1="12"
        y1="16"
        x2="12"
        y2="12"
      /><line x1="12" y1="8" x2="12.01" y2="8" /></svg
    >
  </button>
</div>

<style>
  p {
    margin: 1rem 0;
  }
  em,
  .italic {
    font-style: italic;
  }
  a {
    color: currentColor;
  }
  p {
    margin: 1rem 0;
  }
  em,
  .italic {
    font-style: italic;
  }
  a {
    color: currentColor;
  }
</style>
