<script lang="ts">
  import { afterUpdate } from "svelte";
  import Badge from "./Badge.svelte";
  import { colors } from "../store";

  const sunSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line></svg>`;
  const moonSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg>`;

  let isDark = false;

  function toggleTheme() {
    isDark = !isDark;
    setVariables();
  }

  function setVariables() {
    const previewElement = document.getElementById("preview");
    Object.entries($colors)
      .filter(([name]) => name.includes(isDark ? "Dark" : "Light"))
      .forEach(([, color]) => {
        previewElement.style.setProperty(
          `--${color.label.toLowerCase().replace(" ", "-")}`,
          `rgb(${color.rgb})`
        );
      });
  }

  afterUpdate(() => {
    setVariables();
  });
</script>

<div id="preview">
  <div class="header">
    <button class="theme-switcher" on:click={toggleTheme}
      >{@html isDark ? sunSvg : moonSvg}
      <span class="sr-only"
        >Switch preview to {isDark ? "light" : "dark"} mode</span
      >
    </button>
    <h2>Color palette preview</h2>
  </div>
  <div class="preview-with-result">
    <div class="result">
      <Badge {isDark} contrast="textOnBackground" />
    </div>
    <p>
      Start by picking colors in the first section. This preview and its WCAG 2
      results for contrast will adapt automatically.
    </p>
  </div>
  <p>
    Note that the contrast ratio between two colors is the same without
    distinction of which is used as text or background color.
  </p>
  <div class="button-group preview-with-result">
    <div class="result">
      <Badge {isDark} contrast="primaryOnBackground" />
    </div>
    <span class="button">Primary Button</span>
    <span class="link">Primary Link</span>
  </div>
  <div class="button-group preview-with-result">
    <div class="result">
      <Badge {isDark} contrast="accentOnBackground" />
    </div>
    <span class="button accent">Accent Button</span>
    <span class="link accent">Accent Link</span>
  </div>
  <aside>
    <h3>Side-note</h3>
    <div class="preview-with-result">
      <div class="result">
        <Badge {isDark} contrast="textOnBackgroundSecondary" />
      </div>
      <p>
        The exact same colors are tested against the secondary background color.
      </p>
    </div>
    <p>
      Don't forget that you can switch from light to dark mode to build an
      accessible dark palette.
    </p>
    <div class="button-group preview-with-result">
      <div class="result">
        <Badge {isDark} contrast="primaryOnBackgroundSecondary" />
      </div>
      <span class="button">Primary Button</span>
      <span class="link">Primary Link</span>
    </div>
    <div class="button-group preview-with-result">
      <div class="result">
        <Badge {isDark} contrast="accentOnBackgroundSecondary" />
      </div>
      <span class="button accent">Accent Button</span>
      <span class="link accent">Accent Link</span>
    </div>
  </aside>
</div>

<style>
  #preview {
    height: 100%;
    padding: var(--margin-vertical) var(--margin-horizontal)
      var(--margin-vertical) calc(var(--margin-horizontal) + 3rem);
    color: var(--text);
    background-color: var(--background);
    box-shadow: var(--shadow-offset) 1rem var(--shadow-color);
  }
  .preview-with-result {
    display: flex;
    align-items: center;
    position: relative;
  }
  .result {
    position: absolute;
    left: -5rem;
  }
  aside .result {
    left: -6.5rem; /* add 1.5 aside padding to .result neg margin */
  }
  #preview > *,
  aside > * {
    margin: 1rem 0;
  }
  #preview > *:first-child {
    margin-top: 0;
  }
  .header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
  h2 {
    font-size: var(--font-size-emphasis);
  }
  .link {
    color: var(--primary);
    text-decoration: underline;
  }
  .button {
    color: var(--background);
    background-color: var(--primary);
    border-radius: var(--border-radius-pill);
    padding: 0.5rem 1rem;
    white-space: nowrap;
    /* reset */
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .button.accent {
    background-color: var(--accent);
  }
  .link.accent {
    color: var(--accent);
  }
  aside {
    margin-top: var(--margin-vertical) !important; /* shh */
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    background-color: var(--background-secondary);
  }

  aside .button {
    color: var(--background-secondary);
  }
  .theme-switcher {
    cursor: pointer;
    display: flex;
    padding: 0.5rem;
    border-radius: 100%;
    background-color: var(--color-bg);
    color: var(--color-text);
    box-shadow: var(--shadow-offset) 0.4rem var(--shadow-color);
    transition: box-shadow ease-in-out 200ms;
    /* reset */
    border: none;
  }
  .theme-switcher:hover {
    box-shadow: 0px 0.5rem 0.7rem var(--shadow-color);
  }
  .button-group {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  @media screen and (max-width: 600px) {
    #preview {
      padding-left: calc(var(--margin-horizontal) + 1rem);
    }
    .result {
      position: absolute;
      left: -2rem;
    }
    aside .result {
      left: -3.5rem; /* add 1.5 aside padding to .result neg margin */
    }
  }
</style>
