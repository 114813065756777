<script lang="ts">
  import { colors } from "../store";
  import Input from "./Input.svelte";

  export let theme: string;
</script>

<form>
  <fieldset>
    <legend>{theme} mode</legend>
    <div>
      {#each Object.entries($colors).filter( ([name]) => name.includes(theme) ) as color}
        <Input {color} />
      {/each}
    </div>
  </fieldset>
</form>

<style>
  form {
    width: 100%;
  }
  legend {
    /* style like an h3 */
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-bold);
    margin-bottom: 1rem;
    /* reset legend */
    padding-left: 0;
  }
  fieldset {
    /* Reset */
    border: none;
    padding: 0;
  }
  fieldset > div {
    border-radius: 0.5rem;
    box-shadow: var(--shadow-offset) 0.7rem var(--shadow-color);
    overflow: hidden;
  }
</style>
