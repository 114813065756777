<script lang="ts">
  import prism from "prismjs";
  import { formValues } from "../store";
  import { Modal, showModal } from "svelte-micromodal";

  function generateHslString(hsl: number[]): string {
    return `hsl(${hsl[0]}deg, ${hsl[1]}%, ${hsl[2]}%)`;
  }

  $: source = `:root {
    --color-bg: ${generateHslString($formValues.backgroundLight)};
    --color-bg-secondary: ${generateHslString(
      $formValues.backgroundSecondaryLight
    )};
    --color-text: ${generateHslString($formValues.textLight)};
    --color-primary: ${generateHslString($formValues.primaryLight)};
    --color-accent: ${generateHslString($formValues.accentLight)};
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-bg: ${generateHslString($formValues.backgroundDark)};
    --color-bg-secondary: ${generateHslString(
      $formValues.backgroundSecondaryDark
    )};
    --color-text: ${generateHslString($formValues.textDark)};
    --color-primary: ${generateHslString($formValues.primaryDark)};
    --color-accent: ${generateHslString($formValues.accentDark)};
  }
}`;

  async function copyToClipboard() {
    const liveRegion = document.getElementById("liveregion");
    try {
      // copy to clipboard
      await navigator.clipboard.writeText(source);
      // show success message
      const successMessage = `<p id="copy-status" style="color:var(--color-text-success);">Copied to clipboard!</p>`;
      liveRegion.innerHTML = successMessage;
    } catch (error) {
      // show error message
      const errorMessage = `<p id="copy-status" style="color:var(--color-text-danger);">Couldn't copy to the clipboard.</p>`;
      liveRegion.innerHTML = errorMessage;
    } finally {
      setTimeout(() => {
        /**
         * TODO: make svelte-micromodal accept a custom onClose to clear the
         * status messages on close.
         * (expose all MicroModal.init() config: https://micromodal.vercel.app/)
         */
        const statusEl = document.getElementById("copy-status");
        statusEl.remove();
      }, 3000);
    }
  }
</script>

<Modal
  title="Copy CSS"
  id="export-modal"
  closeLabel="Cancel"
  containerStyles="color:var(--color-text);background-color:var(--color-bg-alt);"
>
  <pre>{@html prism.highlight(source, prism.languages["css"], "css")}</pre>
  <div class="modal-actions">
    <button on:click={copyToClipboard}>Copy</button>
    <!-- TODO: test with SR -->
    <div id="liveregion" role="status" aria-live="polite" />
  </div>
</Modal>
<button class="menu-button" on:click={() => showModal("export-modal")}>
  Copy CSS
  <svg
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path
      d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"
    />
    <rect x="8" y="2" width="8" height="4" rx="1" ry="1" />
  </svg>
</button>

<style>
  pre {
    font-family: var(--font-monospace);
    white-space: pre-wrap;
    margin: 2rem 0;
  }
  .modal-actions button {
    cursor: pointer;
    border: none;
    background-color: var(--color-text);
    color: var(--color-text-reversed);
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius-pill);
  }
</style>
